import { RootState } from '@/reducers';
import { socket } from '@/shared/config/socket';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { AppDispatch } from '../../store';
import TheAside from './TheAside';
import TheContent from './TheContent';
import TheHeader from './TheHeader';
import TheSidebar from './TheSidebar';
import { setBodyWidth } from './reducer';
import noti from '../../assets/noti.mp3'
import axios from '../../shared/config/axios-interceptor'

const TheLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { ref: bodyRef, width: bodyWidth } = useResizeDetector();
  const { user } = useSelector((state: RootState) => state.authentication);
  const audioRef: any = useRef(null)


  const getNotSeen = async () => {
    try {
      const [data1] = await Promise.all([
        axios.get('/room/not-seen'),
      ])
      
      if (data1?.data) {
        audioRef.current.play().catch((error: any) => {
          console.log('Audio play failed:', error)
        })
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }


  useEffect(() => {
    const interval = setInterval(() => {
      getNotSeen()
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    dispatch(setBodyWidth(bodyWidth || 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyWidth]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on(`user_${user?.id}_new_message`, (newMsg: any) => {
      console.log('Bạn có tin nhắn mới: ', newMsg);
    });

    return () => {
      console.log('disconnected')
      // socket.off('connect');
      socket.off(`user_${user?.id}_new_message`);
      // socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <audio ref={audioRef} src={noti} />
      <TheAside />
      <TheSidebar />

      <div className="wrapper d-flex flex-column min-vh-100 bg-light" ref={bodyRef}>
        <TheHeader />

        <div className="body flex-grow-1">
          <TheContent />
        </div>
        {/* <TheFooter /> */}
      </div>
    </div>
  );
};
export default TheLayout;
