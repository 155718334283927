import React, { useEffect, useRef } from 'react';

const FallingShapes: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // Colors for shapes
  const colors = ['#6690c5', '#b7cd61', '#ec986a', '#b7cd61', '#6690c5'];

  // Function to draw a star
  const drawStar = (ctx: CanvasRenderingContext2D, x: number, y: number, radius: number, color: string) => {
    const spikes = 5;
    const outerRadius = radius;
    const innerRadius = radius / 2;
    let rotation = Math.PI / 2 * 3;
    const step = Math.PI / spikes;

    ctx.beginPath();
    ctx.moveTo(x, y - outerRadius);

    for (let i = 0; i < spikes; i++) {
      ctx.lineTo(
        x + Math.cos(rotation) * outerRadius,
        y + Math.sin(rotation) * outerRadius
      );
      rotation += step;
      ctx.lineTo(
        x + Math.cos(rotation) * innerRadius,
        y + Math.sin(rotation) * innerRadius
      );
      rotation += step;
    }

    ctx.lineTo(x, y - outerRadius);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();
  };

  // Function to draw a circle
  const drawCircle = (ctx: CanvasRenderingContext2D, x: number, y: number, radius: number, color: string) => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, Math.PI * 2, false);
    ctx.fillStyle = color;
    ctx.fill();
  };

  // Function to draw a square
  const drawSquare = (ctx: CanvasRenderingContext2D, x: number, y: number, size: number, color: string) => {
    ctx.beginPath();
    ctx.rect(x - size / 2, y - size / 2, size, size);
    ctx.fillStyle = color;
    ctx.fill();
  };

  // Function to draw a triangle
  const drawTriangle = (ctx: CanvasRenderingContext2D, x: number, y: number, size: number, color: string) => {
    ctx.beginPath();
    ctx.moveTo(x, y - size / 2);
    ctx.lineTo(x - size / 2, y + size / 2);
    ctx.lineTo(x + size / 2, y + size / 2);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();
  };

  // Shape class
  class Shape {
    x: number;
    y: number;
    size: number;
    dy: number;
    color: string;
    type: string;

    constructor(canvasWidth: number, canvasHeight: number) {
      this.x = Math.random() * canvasWidth;
      this.y = Math.random() * canvasHeight;
      this.size = Math.random() * 20 + 10;
      this.dy = Math.random() * 0.5 + 0.5;
      this.color = colors[Math.floor(Math.random() * colors.length)];
      this.type = ['circle', 'star', 'square', 'triangle'][Math.floor(Math.random() * 4)];
    }

    draw(ctx: CanvasRenderingContext2D) {
      switch (this.type) {
        case 'circle':
          drawCircle(ctx, this.x, this.y, this.size / 5, this.color);
          break;
        case 'star':
          drawStar(ctx, this.x, this.y, this.size / 3, this.color);
          break;
        case 'square':
          drawSquare(ctx, this.x, this.y, this.size / 6, this.color);
          break;
        case 'triangle':
          drawTriangle(ctx, this.x, this.y, this.size / 5, this.color);
          break;
      }
    }

    update(canvasWidth: number, canvasHeight: number, ctx: CanvasRenderingContext2D) {
      this.y += this.dy;
      if (this.y > canvasHeight) {
        this.y = 0;
        this.x = Math.random() * canvasWidth;
      }
      this.draw(ctx);
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (canvas && ctx) {
      canvas.width = 500;
      canvas.height = 320;

      const shapeArray: Shape[] = [];
      for (let i = 0; i < 100; i++) {
        shapeArray.push(new Shape(canvas.width, canvas.height));
      }

      const animate = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        shapeArray.forEach(shape => shape.update(canvas.width, canvas.height, ctx));
        requestAnimationFrame(animate);
      };

      animate();

      const handleResize = () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return <canvas style={{opacity: 0.9, zIndex: 1}} className='w-100 h-100 position-absolute top-0 left-0  bottom-0 right-0' ref={canvasRef} />;
};

export default FallingShapes;
