import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../reducers';
import { INotify, INewNotify } from '../../../shared/model/notify.model';
import { IInitialState, IResponse } from '../../../shared/shared-interfaces';

import {
  createEntity,
  getEntities,
  getEntity,
  INotifyParams,
  removeEntity,
  updateDeleteEntity,
  updateEntity,
} from './notiManagement.api';

interface ITransactionInitialState extends IInitialState {
  updateEntityStatusSuccess: boolean;
  filterState: INotifyParams;
  currentDraw: INotify | null;
}

export const initialUsersFilter: INotifyParams = {
  page: 1,
  limit: 15,
  sortBy: 'id',
  sortOrder: 'DESC',
};

const initialState: ITransactionInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  updateEntityStatusSuccess: false,
  filterState: initialUsersFilter,
  currentDraw: null
};

export const notifyAdapter = createEntityAdapter<INotify>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'notifySlice',
  initialState: notifyAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<INotifyParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<INotify[]>>>) => {
        notifyAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(getEntities.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    });

    builder.addCase(getEntity.fulfilled.type, (state, { payload }: PayloadAction<INotify>) => {
      notifyAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(getEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    });
    builder.addCase(updateEntity.fulfilled.type, (state, { payload }: PayloadAction<INotify>) => {
      notifyAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(updateEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    });
    builder.addCase(createEntity.fulfilled.type, (state, { payload }: PayloadAction<INotify>) => {
      notifyAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(createEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    });
    builder.addCase(removeEntity.fulfilled.type, (state, { payload }: PayloadAction<string>) => {
      notifyAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(removeEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    });
    builder.addCase(updateDeleteEntity.fulfilled.type, (state, { payload }: PayloadAction<string[]>) => {
      notifyAdapter.removeMany(state, payload);
      state.initialState.totalItems -= payload.length;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(updateDeleteEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    });
  },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const notiSelectors = notifyAdapter.getSelectors<RootState>((state) => state.notifyReducer);

const { selectById } = notifyAdapter.getSelectors();
const getUserState = (rootState: RootState) => rootState.notifyReducer;

export const selectEntityById = (id: string) => {
  return createSelector(getUserState, (state) => selectById(state, id));
};
