import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { ResponseStatus } from '../../../shared/enumeration/ResponseStatus';
import { IParams } from '../../../shared/shared-interfaces';
import { INewTransaction, ITransaction, typeTransaction } from '@/shared/model/transaction.model';

const prefix = 'transaction';
// const transaction = 'revision';

export interface ITransactionParams extends IParams {
  status?: ResponseStatus;
  type: typeTransaction
  // projectId?: number; // Lấy tất cả user không phải là nhân viên của dự án được truyền vào
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: ITransactionParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<ITransaction[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ITransaction>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewTransaction, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: ITransaction, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<ITransaction>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IUserDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IUserDel, thunkAPI) => {
    try {
      await axios.post<ITransaction>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);