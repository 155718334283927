export enum ResponseStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export const responseStatusArray: ResponseStatus[] = [
  ResponseStatus.ACTIVE,
  ResponseStatus.INACTIVE,
  ResponseStatus.DELETED,
];

export const mapResponseStatus: { [key in ResponseStatus]: string } = {
  [ResponseStatus.ACTIVE]: 'Hoạt động',
  [ResponseStatus.INACTIVE]: 'Không hoạt động',
  [ResponseStatus.DELETED]: 'Đã xóa',
};


export enum ResponseStatus1 {
  TANG = 1,
  GIAM = 2,
  KHONGXACDINH = 3,
}

export const responseStatusArray1: ResponseStatus1[] = [
  ResponseStatus1.TANG,
  ResponseStatus1.GIAM,
  ResponseStatus1.KHONGXACDINH,
];

export const mapResponseStatus1: { [key in ResponseStatus1]: string } = {
  [ResponseStatus1.TANG]: 'TĂNG',
  [ResponseStatus1.GIAM]: 'GIẢM',
  [ResponseStatus1.KHONGXACDINH]: 'KHÔNG XÁC ĐỊNH',
};


export const mapResponseStatusToColor: { [key in ResponseStatus]: string } = {
  [ResponseStatus.ACTIVE]: 'success',
  [ResponseStatus.INACTIVE]: 'danger',
  [ResponseStatus.DELETED]: 'gray',
};



export enum ResponseStatusDraw {
  open = 'open',
  draw = 'drawn',
  closed = 'closed',
}

export const mapResponseStatusToColorDraw: { [key in ResponseStatusDraw]: string } = {
  [ResponseStatusDraw.open]: 'success',
  [ResponseStatusDraw.draw]: 'gray',
  [ResponseStatusDraw.closed]: 'danger',
};

export const mapResponseStatusDraw: { [key in ResponseStatusDraw]: string } = {
  [ResponseStatusDraw.open]: 'Đã mở',
  [ResponseStatusDraw.draw]: 'Chưa mở',
  [ResponseStatusDraw.closed]: 'Đã đóng',
};


export enum ResponseStatusTransaction {
  pending = 0,
  success = 1,
  error = 2,
}

export const mapResponseStatusToColorTransaction: { [key in ResponseStatusTransaction]: string } = {
  [ResponseStatusTransaction.success]: 'success',
  [ResponseStatusTransaction.pending]: 'primary',
  [ResponseStatusTransaction.error]: 'danger',
};

export const mapResponseStatusTransaction: { [key in ResponseStatusTransaction]: string } = {
  [ResponseStatusTransaction.pending]: 'Đang chờ',
  [ResponseStatusTransaction.success]: 'Thành công',
  [ResponseStatusTransaction.error]: 'Thất bại',
};


// export enum typeTransaction {
//   deposit = 1, // Nạp tiền
//   withdraw = 2, // Rút tiền
// }

// export enum statusTransaction {
//   pendding = 0, // Đang chờ
//   success = 1, // Thành công
//   error = 2, // Thất bại
// }



export enum ResponseVotedHistory {
  TANGLEN = 1,
  GIAMBOT = 2,
  SUAMOCCHAU = 3,
  SUACOGAIHALAN = 4,
  SUANUTI = 5,
  SUAGOLD = 6,
  SUALADY = 7,
  SUAMILO = 8,
  SUAANFA = 9,
  SUADAUNANH = 10,
  HANGNGAY1 = 11,
  HANGNGAY2 = 12,
}

export const mapResponseColorVotedHistory: { [key in ResponseVotedHistory]: string } = {
  [ResponseVotedHistory.TANGLEN]: 'TĂNG',
  [ResponseVotedHistory.GIAMBOT]: 'GIẢM',
  [ResponseVotedHistory.SUAMOCCHAU]: 'Sữa mộc châu',
  [ResponseVotedHistory.SUACOGAIHALAN]: 'Sữa cô gái hà lan',
  [ResponseVotedHistory.SUANUTI]: 'Sữa nutifood',
  [ResponseVotedHistory.SUAGOLD]: 'Sữa optimun gold',
  [ResponseVotedHistory.SUALADY]: 'Sữa dutch lady',
  [ResponseVotedHistory.SUAMILO]: 'Sữa milo',
  [ResponseVotedHistory.SUAANFA]: 'Sữa anfa gold',
  [ResponseVotedHistory.SUADAUNANH]: 'Sữa đậu nành',
  [ResponseVotedHistory.HANGNGAY1]: 'Hàng ngày',
  [ResponseVotedHistory.HANGNGAY2]: 'Hàng ngày',
};

export const mapResponseVotedHistory: { [key in ResponseVotedHistory]: string } = {
  [ResponseVotedHistory.TANGLEN]: 'TĂNG',
  [ResponseVotedHistory.GIAMBOT]: 'GIẢM',
  [ResponseVotedHistory.SUAMOCCHAU]: 'Sữa mộc châu',
  [ResponseVotedHistory.SUACOGAIHALAN]: 'Sữa cô gái hà lan',
  [ResponseVotedHistory.SUANUTI]: 'Sữa nutifood',
  [ResponseVotedHistory.SUAGOLD]: 'Sữa optimun gold',
  [ResponseVotedHistory.SUALADY]: 'Sữa dutch lady',
  [ResponseVotedHistory.SUAMILO]: 'Sữa milo',
  [ResponseVotedHistory.SUAANFA]: 'Sữa anfa gold',
  [ResponseVotedHistory.SUADAUNANH]: 'Sữa đậu nành',
  [ResponseVotedHistory.HANGNGAY1]: 'Hàng ngày',
  [ResponseVotedHistory.HANGNGAY2]: 'Hàng ngày',
};