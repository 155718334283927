import { ResponseStatusDraw } from '../enumeration/ResponseStatus'
import { IUser } from './user.model'

export enum typeTransaction {
  deposit = 1, // Nạp tiền
  withdraw = 2, // Rút tiền
}

export enum statusTransaction {
  pendding = 0, // Đang chờ
  success = 1, // Thành công
  error = 2, // Thất bại
}

export interface INewTransaction {
  userId: number
  money: number
  type: typeTransaction
  status: statusTransaction
  user?: IUser,
  createdDate?: string
  message?: string
  isNotify?: number
  isSeen?: number
  isSeend?: number
}

export interface ITransaction extends INewTransaction {
  id: number
}
