import {
    CButton,
    CCol,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from '@coreui/react-pro';
import { Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ISelectValue } from '../../../shared/shared-interfaces';
import SModal from '../../shared/Modal/SModal';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { AppDispatch } from '../../../store';
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast';
import { socket } from '@/shared/config/socket';
import { INewNotify } from '@/shared/model/notify.model';
import { createEntity } from './notiManagement.api';

interface IUserUpdateProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    load?: boolean;
    setLoad: (load: boolean) => void;
}

const RechargeAdd = (props: IUserUpdateProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { initialState } = useSelector((state: RootState) => state.rechargeReducer);
    const { updateEntitySuccess } = initialState;
    const { visible, setVisible, load, setLoad } = props;
    const handleOnClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (updateEntitySuccess) {
            setVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateEntitySuccess]);


    const initialValues: INewNotify = {
        kyId: 0,
        userId: 0,
        message: '',
        money: 0
    };

    const validationSchema = Yup.object().shape({
        userId: Yup.number().typeError('Vui lòng nhập số').required('Không được để trống').nullable(),
        kyId: Yup.number().typeError('Vui lòng nhập số').required('Không được để trống').nullable(),
        money: Yup.number().typeError('Vui lòng nhập số').required('Không được để trống').nullable(),
        message: Yup.string().required('Không được để trống').nullable(),
    });

    const returnOption = (optionValue: number | null | undefined, array: ISelectValue<string>[]) => {
        if (!optionValue) return null;
        if (!array.length) return null;
        return array.find(({ value }) => Number(value) === optionValue);
    };


    useEffect(() => {
        socket.on(`user_3_new_deposit`, (data: any) => {
            console.log('đã nhận được kết quả', data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
        <SModal visible={visible} onClose={handleOnClose} backdrop="static" className="custom-modal">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                // validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(value) => {

                    if(!value.userId || !value?.kyId || !value?.message){
                        ToastError('Vui lòng nhập thông tin đầy đủ');
                        return;
                    }

                    const data = {
                        userId: value.userId,
                        kyId: value.kyId,
                        message: value.message,
                        money: value.money
                    };
                    dispatch(createEntity(data));
                    setLoad(!load);
                    setVisible(!visible);
                    ToastSuccess('Tạo lời nhắn thành công!')
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }) => (
                    <CForm onSubmit={handleSubmit} className="custom-form">
                        <>
                            <CModalHeader>{'Tạo lời nhắn'}</CModalHeader>
                            <CModalBody>
                                <CRow className="gap-20">

                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">ID người chơi</CFormLabel>

                                        <CFormInput
                                            className="form-height-44"
                                            name="userId"
                                            autoComplete="off"
                                            value={values.userId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={!!errors.userId && touched.userId}
                                        />
                                        <CFormFeedback invalid className={!!errors.userId && touched.userId ? 'd-block' : 'd-none'}>
                                            {errors.userId}
                                        </CFormFeedback>
                                    </CCol>


                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">Mã phiên</CFormLabel>

                                        <CFormInput
                                            className="form-height-44"
                                            name="kyId"
                                            autoComplete="off"
                                            value={values.kyId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={!!errors.kyId && touched.kyId}
                                        />
                                        <CFormFeedback invalid className={!!errors.kyId && touched.kyId ? 'd-block' : 'd-none'}>
                                            {errors.kyId}
                                        </CFormFeedback>
                                    </CCol>


                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">Số tiền</CFormLabel>

                                        <CFormInput
                                            className="form-height-44"
                                            name="money"
                                            autoComplete="off"
                                            value={values.money}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={!!errors.money && touched.money}
                                        />
                                        <CFormFeedback invalid className={!!errors.money && touched.money ? 'd-block' : 'd-none'}>
                                            {errors.money}
                                        </CFormFeedback>
                                    </CCol>

                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">Nội dung</CFormLabel>

                                        <CFormTextarea
                                            className="form-height-44"
                                            name="message"
                                            autoComplete="off"
                                            value={values.message}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={!!errors.message && touched.message}
                                        />
                                        <CFormFeedback invalid className={!!errors.message && touched.message ? 'd-block' : 'd-none'}>
                                            {errors.message}
                                        </CFormFeedback>
                                    </CCol>

                                </CRow>
                            </CModalBody>
                            <CModalFooter className="d-flex justify-content-end">
                                <CButton
                                    className="btn-custom minw-120 variant-gray-300"
                                    type="button"
                                    onClick={() => {
                                        resetForm();
                                        setVisible(false);
                                    }}
                                >
                                    Huỷ
                                </CButton>
                                <CButton className="btn-custom minw-120 primary-500" type="submit">
                                    Lưu
                                </CButton>
                            </CModalFooter>
                        </>
                    </CForm>
                )}
            </Formik>
        </SModal>
    </>

    );
};

export default RechargeAdd;
