import axios from 'axios';
import { ToastError } from '../../components/shared/toast/Toast';
import { ErrorMessage, mapErrorMessage } from '../enumeration/errorMessage';
import { SERVER_API_URL } from './constants';

const TIMEOUT = 10 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const instance = axios.create({
  baseURL: SERVER_API_URL,
  timeout: TIMEOUT,
});

const onRequestSuccess = (config: any) => {
  const token = localStorage.getItem('authentication_token') || sessionStorage.getItem('authentication_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const onResponseSuccess = (response: any) => response;

const onResponseError = (err: any) => {
  let errorMessage = '';
  if(err?.response && err?.response?.data && err?.response?.data?.code){
    errorMessage = mapErrorMessage[err?.response?.data?.code as ErrorMessage] || err?.response?.data?.code;    
  }else{
     errorMessage = mapErrorMessage[err?.code as ErrorMessage] || err.message;
  }
  ToastError(errorMessage)
  return Promise.reject(err);
};

instance.interceptors.request.use(onRequestSuccess);
instance.interceptors.response.use(onResponseSuccess, onResponseError);

export default instance;
