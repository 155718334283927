import { useRouter } from "@/shared/utils/hooks/useRouter";
import { useEffect, useState } from "react";
import Layout from "./Layout";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from "@coreui/react-pro";
import { ITransaction } from "@/shared/model/transaction.model";
import axios from '../../../shared/config/axios-interceptor'
import { RootState } from "@/reducers";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { ResponseStatusTransaction, mapResponseStatusToColorTransaction, mapResponseStatusTransaction } from "@/shared/enumeration/ResponseStatus";

const HistoryWithdraw = () => {
    const { navigate } = useRouter();
    const { user } = useSelector((state: RootState) => state.authentication);

    const [data, setData] = useState<ITransaction[] | []>([])

    const getData = async () => {
        try {
            const response = await axios.get(
                '/transaction?type=2&sortOrder=DESC&sortBy=id&limit=20&userId=' + user?.id,
            )
            setData(response.data.data)
        } catch (error) {
            setData([])
            console.error('Error:', error)
        }
    }


    useEffect(() => {
        getData();
    }, [])


    return <Layout >
        {/* <div className="box-history-order">


            <div className="box-header d-flex justify-content-center align-items-center">

                <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />

                <h5 className="text-white text-center mb-0 mt-0">Lịch sử rút tiền</h5>

            </div>


            <div className="box-history">

                {

                    data.map((item, index) => {
                        return <div className="item" key={index}>
                            <div className="d-flex justify-content-between">
                                <div>Rút tiền: {new Intl.NumberFormat('vi-VN').format(item?.money || 0)}</div>
                                <div className={'fw-bold text-' + mapResponseStatusToColorTransaction[item?.status]}>{mapResponseStatusTransaction[item?.status]}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div style={{fontSize: 14, marginTop: 4, color: "#888"}}>{dayjs(item.createdDate).format('DD/MM/YYYY HH:mm')}</div>
                            </div>
                        </div>
                    })

                }

            </div>


        </div> */}

        <div className="box-history-order">


            <div className="box-header">
                <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />
                <h4 className="text-white text-center mb-0 mt-1" style={{ fontSize: 15 }}>Lịch sử rút</h4>
            </div>

            <div className="px-3">
                <div className="rounded-3 mt-3 py-1 px-3 text-white" style={{ background: "#eb426d", fontSize: 13 }}>
                    Tổng số: 0
                </div>
            </div>

            <div className="px-3 mt-3">
                <div className="d-flex justify-content-between py-1 px-3 rounded-3" style={{ background: "#d1d5db", fontSize: 12, color: "rgb(55, 65, 81)" }}>
                    <div style={{ width: "10%" }}>ID</div>
                    <div style={{ width: "21%" }}>Thay đổi</div>
                    <div style={{ width: "20%" }}>Nội dung</div>
                    <div style={{ width: "17%" }}>Trạng thái</div>
                    <div style={{ width: "32%" }} className="text-end">Ngày thực hiện</div>
                </div>
            </div>

            <div className="box-history mt-3 pt-0">
                {
                    data.map((item, index) => {
                        return <div key={index} className="d-flex justify-content-between py-1 px-3 mb-2" style={{ fontSize: 12, color: "rgb(55, 65, 81)" }}>
                            <div style={{ width: "10%" }}>{item?.id}</div>
                            <div style={{ width: "21%" }}>-{((new Intl.NumberFormat('vi-VN').format(item?.money || 0)))}</div>
                            <div style={{ width: "20%" }}>Rút tiền</div>
                            <div style={{ width: "17%" }}>{mapResponseStatusTransaction[item?.status]}</div>
                            <div style={{ width: "32%" }} className="text-end">{new Date(item?.createdDate || "").toLocaleDateString()}</div>
                        </div>
                    })
                }

            </div>



        </div>
    </Layout>
}

export default HistoryWithdraw;