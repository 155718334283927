import Edit02Icon from '@/components/shared/icons/Edit02Icon'
import {
	CButton,
	CInputGroup,
	CInputGroupText,
	CRow,
	CSmartTable,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../reducers'
import { createIndexes } from '../../../shared/utils/ultils'
import { AppDispatch } from '../../../store'
import CustomTableFooter from '../../shared/CustomTableFooter/CustomTableFooter'
import { getEntities } from './notiManagement.api'
import {
	fetching,
	notiSelectors,
	resetEntity,
	setFilterState,
} from './notiManagement.reducer'
import dayjs from 'dayjs'
import PlusIcon from '@/components/shared/icons/PlusIcon'
import NotifyAdd from './NotiAdd'
import NotifyAddKM from './NotiAddKM'
import { INotify } from '@/shared/model/notify.model'

const columns = [
	{
		key: 'id',
		label: 'Mã',
		sorter: false,
	},
	{
		key: 'userId',
		label: 'ID người chơi',
		sorter: false,
	},

	{
		key: 'kyId',
		label: 'Mã phiên',
		sorter: false,
	},
	{
		key: 'money',
		label: 'Số tiền',
		sorter: false,
	},
	{
		key: 'message',
		label: 'Lời nhắn',
		sorter: false,
	},
	{
		key: 'createdDate',
		label: 'Thời gian',
		sorter: false,
	}
]

interface IIndexNotify extends INotify {
	index: number
}

const NotifyManagement = () => {
	const dispatch = useDispatch<AppDispatch>()
	const { initialState } = useSelector(
		(state: RootState) => state.notifyReducer,
	)
	const {
		filterState,
		totalItems,
		totalPages,
		loading,
		updateEntitySuccess,
		deleteEntitySuccess,
	} = initialState
	const [createTransaction, setCreateTransaction] = useState<boolean>(false)
	const [
		updateTransaction,
		setUpdateTransaction,
	] = useState<INotify | null>(null)
	const [addTransaction, setAddTransaction] = useState<Boolean>(false)
	const [addTransactionKM, setAddTransactionKM] = useState<Boolean>(false)
	const [load, setLoad] = useState<boolean>(false)
	const { user } = useSelector((state: RootState) => state.authentication);

	const handleCancelAddTransaction = (update?: boolean) => {
		setAddTransaction(false)
	}

	const handleCancelAddTransactionKM = (update?: boolean) => {
		setAddTransactionKM(false)
	}

	const handlePaginationChange = (page: number) => {
		if (page !== 0) {
			window.scrollTo(0, 0)
			dispatch(setFilterState({ ...filterState, page: page }))
		}
	}

	const notifi = useSelector(notiSelectors.selectAll)
	const indexedTransaction = createIndexes(notifi, filterState)
	console.log(notifi)

	useEffect(() => {
		dispatch(fetching())
		dispatch(getEntities(filterState))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(filterState), load])


	useEffect(() => {
		dispatch(getEntities(filterState))
		dispatch(resetEntity());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateEntitySuccess]);

	const handleShowAddTransaction = () => {
		setAddTransaction(true)
	}

	const handleShowAddTransactionKM = () => {
		setAddTransactionKM(true)
	}


	return (
		<>
			<div className="tab-outlet-content">
				<div className="pb-4">
					<CButton
						className="btn-custom primary-500 btn-sm"
						onClick={handleShowAddTransaction}
					>
						<PlusIcon /> Lời nhắn
					</CButton>
				</div>
				<div className="table-content">
					<CSmartTable
						header
						columns={columns}
						items={indexedTransaction}
						clickableRows
						noItemsLabel="Không có bản ghi"
						itemsPerPage={filterState.limit}
						scopedColumns={{
							result: ({ index, ...value }: IIndexNotify) => <td></td>,
							money: ({ index, ...value }: IIndexNotify) => <td>
								{new Intl.NumberFormat('vi-VN').format(value?.money || 0)}
								</td>,
							createdDate: ({ index, ...value }: IIndexNotify) => {
								return (
									<td>
										<div className={'d-flex align-items-center flex-nowrap'}>
											{dayjs(value.createdDate).format('DD/MM/YYYY HH:mm:ss')}
										</div>
									</td>
								)
							},
							// actions: ({ index, ...value }: IIndexNotify) => {
							// return (
							// <td>
							// <CInputGroup className="custom-input-group-action justify-content-center">
							// <CInputGroupText onClick={handleUpdateTransaction(value)}>
							// <Edit02Icon />
							// </CInputGroupText>
							// </CInputGroup>
							// </td>
							// )
							// },
						}}
						tableProps={{
							hover: true,
							align: 'middle',
							responsive: 'lg',
							className: 'custom-table',
						}}
						tableHeadProps={{
							className: 'table-head',
						}}
						tableBodyProps={{
							className: 'table-body',
						}}
					/>
				</div>
				<CRow>
					<CustomTableFooter
						totalItems={totalItems}
						hideSideChosen
						totalPages={totalPages}
						filterState={filterState}
						setFilterState={(filter) =>
							dispatch(setFilterState({ ...filter }))
						}
						handlePaginationChange={handlePaginationChange}
					/>
				</CRow>

				<NotifyAdd
					visible={Boolean(addTransaction)}
					load={load}
					setLoad={setLoad}
					setVisible={handleCancelAddTransaction}
				/>

				<NotifyAddKM
					visible={Boolean(addTransactionKM)}
					load={load}
					setLoad={setLoad}
					setVisible={handleCancelAddTransactionKM}
				/>
			</div>
		</>
	)
}

export default NotifyManagement
