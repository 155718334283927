import {
    CButton,
    CCol,
    CForm,
    CFormCheck,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from '@coreui/react-pro';
import { Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ISelectValue } from '../../../shared/shared-interfaces';
import SModal from '../../shared/Modal/SModal';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { AppDispatch } from '../../../store';
import { INewTransaction, statusTransaction, typeTransaction } from '@/shared/model/transaction.model';
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast';
import { socket } from '@/shared/config/socket';

interface IUserUpdateProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    load?: Boolean;
    setLoad: (load: boolean) => void;
}

const RechargeAddKM = (props: IUserUpdateProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { initialState } = useSelector((state: RootState) => state.rechargeReducer);
    const { updateEntitySuccess } = initialState;
    const { visible, setVisible, load, setLoad } = props;
    const handleOnClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (updateEntitySuccess) {
            setVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateEntitySuccess]);


    const initialValues: INewTransaction = {
        money: 0,
        status: statusTransaction.pendding,
        type: typeTransaction.withdraw,
        userId: 0,
        message: 'Chúc mừng Quý Khách đã may mắn trúng giải BONUS từ Hệ thống với số tiền thưởng là 49.789.000 VNĐ. Quý khách vui lòng liên hệ CSKH để biết thêm chi tiết !',
        isNotify: 0
    };

    const validationSchema = Yup.object().shape({
        userId: Yup.number().typeError('Vui lòng nhập số').required('Không được để trống').nullable(),
        money: Yup.number().typeError('Vui lòng nhập số').required('Không được để trống').nullable(),
    });

    const returnOption = (optionValue: number | null | undefined, array: ISelectValue<string>[]) => {
        if (!optionValue) return null;
        if (!array.length) return null;
        return array.find(({ value }) => Number(value) === optionValue);
    };

    return (<>
        <SModal visible={visible} onClose={handleOnClose} backdrop="static" className="custom-modal">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                // validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(value) => {
            
                    if(!value.userId || !value?.money){
                        ToastError('Vui lòng nhập thông tin đầy đủ');
                        return;
                    }

                    const data = {
                        userId: value.userId,
                        money: value.money,
                        message: value.message,
                        isNotify: value.isNotify,
                    };

                    socket.emit('send_deposit', data);
                    setLoad(!load);
                    setVisible(!visible);
                    ToastSuccess('Nạp tiền thành công')
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }) => (
                    <CForm onSubmit={handleSubmit} className="custom-form">
                        <>
                            <CModalHeader>{'Thêm lệnh nạp tiền khuyến mãi mới'}</CModalHeader>
                            <CModalBody>
                                <CRow className="gap-20">

                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">ID người chơi</CFormLabel>

                                        <CFormInput
                                            className="form-height-44"
                                            name="userId"
                                            autoComplete="off"
                                            value={values.userId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={!!errors.userId && touched.userId}
                                        />
                                        <CFormFeedback invalid className={!!errors.userId && touched.userId ? 'd-block' : 'd-none'}>
                                            {errors.userId}
                                        </CFormFeedback>
                                    </CCol>


                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">Số tiền nạp</CFormLabel>

                                        <CFormInput
                                            className="form-height-44"
                                            name="money"
                                            autoComplete="off"
                                            value={values.money}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={!!errors.money && touched.money}
                                        />
                                        <CFormFeedback invalid className={!!errors.money && touched.money ? 'd-block' : 'd-none'}>
                                            {errors.money}
                                        </CFormFeedback>
                                    </CCol>

                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">Nội dung</CFormLabel>

                                        <CFormTextarea
                                            name="message"
                                            autoComplete="off"
                                            value={values.message}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={!!errors.message && touched.message}
                                            style={{ minHeight: '150px' }}
                                        />
                                        <CFormFeedback invalid className={!!errors.message && touched.message ? 'd-block' : 'd-none'}>
                                            {errors.message}
                                        </CFormFeedback>
                                    </CCol>


                                    {/* {mapModulePermissionToArray[item].map((item, idx) => (
                                <CCol xs={12} lg={6} className="mb-3" key={`${item}-${idx}`}>
                                  <CFormCheck
                                    id={`${item}-${idx}`}
                                    name="permissionsArray"
                                    checked={values.permissionsArray.includes(item)}
                                    value={item}
                                    label={mapPermissionsToName[item]}
                                    onChange={handleChange}
                                  />
                                </CCol>
                              ))} */}



                                    <CCol xs="12">
                                        <CFormCheck
                                            type="checkbox"
                                            className="form-height-44"
                                            id='isNotify'
                                            name="isNotify"
                                            autoComplete="off"
                                            value={1}
                                            onChange={(e) =>  setFieldValue('isNotify', e.target.checked ? 1 : 0, true)}
                                            checked={values.isNotify === 1}
                                        />
                                        <CFormLabel htmlFor='isNotify' className="ms-2 mb-6 label-gray-700 cursor-pointer">Hiển thị thông báo</CFormLabel>
                                    </CCol>

                                </CRow>
                            </CModalBody>
                            <CModalFooter className="d-flex justify-content-end">
                                <CButton
                                    className="btn-custom minw-120 variant-gray-300"
                                    type="button"
                                    onClick={() => {
                                        resetForm();
                                        setVisible(false);
                                    }}
                                >
                                    Huỷ
                                </CButton>
                                <CButton className="btn-custom minw-120 primary-500" type="submit">
                                    Lưu
                                </CButton>
                            </CModalFooter>
                        </>
                    </CForm>
                )}
            </Formik>
        </SModal>
    </>

    );
};

export default RechargeAddKM;
