import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { ResponseStatus } from '../../../shared/enumeration/ResponseStatus';
import { IParams } from '../../../shared/shared-interfaces';
import { INewTransaction } from '@/shared/model/transaction.model';
import { INewNotify, INotify } from '@/shared/model/notify.model';

const prefix = 'notify';

export interface INotifyParams extends IParams {
}

export const getEntities = createAsyncThunk(`get-all-recharge-${prefix}`, async (fields: INotifyParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<INotify[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-recharge-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<INotify>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-recharge-${prefix}`, async (body: INewNotify, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-recharge-${prefix}`, async (body: INotify, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<INotify>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-recharge-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IUserDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IUserDel, thunkAPI) => {
    try {
      await axios.post<INotify>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);