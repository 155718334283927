import { IHistory } from '@/shared/model/history.model'
import Layout from './Layout'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import {
  CButton,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import { IGame } from '@/shared/model/game.model'
import info from '../../../assets/img/info.jpg'
import signed from '../../../assets/img/signed.png'
import partners from '../../../assets/img/partners.png'

const History = () => {
  const [games, setGames] = useState<IGame[] | []>([])
  const getKetQua = async () => {
    try {
      const response = await axios.get(
        '/draws?status=closed&sortOrder=DESC&sortBy=id&limit=20',
      )
      setGames(response.data.data)
    } catch (error) {
      setGames([])
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getKetQua()
  }, [])

  return (
    <Layout>
      <div className="box-history">
        <div className="box-header">
          <div className="text-white fw-normal m-0">Giới thiệu</div>
        </div>


        <div className='px-3 mt-3'>


          <img className='w-100' src={info} />


          <div className="mt-3">
            <p>❣️ Bạn không muốn một mối tình tạm bợ ?</p>
            <p>❣️ Bạn lười bắt đầu một mối quan hệ mới với những câu làm quen nhàm chán ?</p>
            <p>❣️ Bạn lười chữa lành những vết thương sau những lần đổ vỡ</p>
            <p>❣️ Bạn lười giải thích, càng chẳng biết phải bày tỏ những cảm xúc trong lòng mình với người mới ra sao.</p>
            <p>❣️ Bạn lười mở lòng và không muốn tin vào ai nữa.</p>
            <p>➡️ Chúng tôi hiểu rằng việc tìm kiếm một người phù hợp không phải là điều dễ dàng, đặc biệt khi bạn đã trải qua những trăn trở trong quá khứ.</p>
            <p>‼️ 𝗧𝗮̂𝗺 𝘀𝘂̛̣ 𝗰𝘂̀𝗻𝗴 𝗰𝗵𝘂́𝗻𝗴 𝘁𝗼̂𝗶</p><p>‼️ 𝐵𝑎̉𝑜 𝑀𝑎̣̂𝑡 - 𝐶ℎ𝑎̂𝑛 𝑇ℎ𝑎̀𝑛ℎ - 𝑇𝑖𝑛 𝐶𝑎̣̂𝑦</p>
          </div>




          <div className='d-flex justify-content-between align-items-center bg-white px-3 py-2' style={{ borderLeftColor: "rgb(55, 65, 81)", borderLeftWidth: 2, borderLeftStyle: "solid" }}>
            <h4 className='m-0' style={{ fontSize: 16, fontWeight: 500 }}>Thống kê</h4>
            <svg data-v-bd832875="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-[18px]" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M14.06 9.94L12 9l2.06-.94L15 6l.94 2.06L18 9l-2.06.94L15 12zM4 14l.94-2.06L7 11l-2.06-.94L4 8l-.94 2.06L1 11l2.06.94zm4.5-5l1.09-2.41L12 5.5L9.59 4.41L8.5 2L7.41 4.41L5 5.5l2.41 1.09zm-4 11.5l6-6.01l4 4L23 8.93l-1.41-1.41l-7.09 7.97l-4-4L3 19z"></path></svg>
          </div>

          <div className="px-3 py-2 text-sm bg-white mt-3">
            <CRow className='g-3'>

              <CCol xs={6} >
                <div>
                  <div className="fw-bold mb-1">80</div>
                  <div className="text-sm">Số lượng đối tác</div>
                </div>
              </CCol>


              <CCol xs={6} >
                <div>
                  <div className="fw-bold mb-1">12598</div>
                  <div className="text-sm">Thành viên</div>
                </div>
              </CCol>


              <CCol xs={6} >
                <div>
                  <div className="fw-bold mb-1">2600</div>
                  <div className="text-sm">Cộng tác viên</div>
                </div>
              </CCol>

              <CCol xs={6} >
                <div>
                  <div className="fw-bold mb-1">6100</div>
                  <div className="text-sm">Khách hàng tiềm năng</div>
                </div>
              </CCol>


            </CRow>

          </div>


          <div className='d-flex justify-content-between align-items-center bg-white px-3 py-2 mt-3' style={{ borderLeftColor: "rgb(55, 65, 81)", borderLeftWidth: 2, borderLeftStyle: "solid" }}>
            <h4 className='m-0' style={{ fontSize: 16, fontWeight: 500 }}>Hệ thống đầu tư</h4>
            <svg data-v-bd832875="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-[18px]" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 8h4"></path><path stroke-width="1.5" d="M20.833 9h-2.602C16.446 9 15 10.343 15 12s1.447 3 3.23 3h2.603c.084 0 .125 0 .16-.002c.54-.033.97-.432 1.005-.933c.002-.032.002-.071.002-.148v-3.834c0-.077 0-.116-.002-.148c-.036-.501-.465-.9-1.005-.933C20.959 9 20.918 9 20.834 9Z"></path><path stroke-width="1.5" d="M20.965 9c-.078-1.872-.328-3.02-1.137-3.828C18.657 4 16.771 4 13 4h-3C6.229 4 4.343 4 3.172 5.172S2 8.229 2 12s0 5.657 1.172 6.828S6.229 20 10 20h3c3.771 0 5.657 0 6.828-1.172c.809-.808 1.06-1.956 1.137-3.828"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.991 12h.01"></path></g></svg>
          </div>

          <div className='d-flex justify-content-between align-items-center bg-white px-3 py-2 mt-3' style={{ borderLeftColor: "rgb(55, 65, 81)", borderLeftWidth: 2, borderLeftStyle: "solid" }}>
            <h4 className='m-0' style={{ fontSize: 16, fontWeight: 500 }}>Đối tác lớn</h4>
          </div>


          <div className='text-center bg-white px-3 py-2 mt-3'>
            <img className='w-100' src={partners} />
          </div>


          <div className='d-flex  align-items-center bg-white px-3 py-2 mt-3 gap-3' style={{ borderLeftColor: "rgb(55, 65, 81)", borderLeftWidth: 2, borderLeftStyle: "solid" }}>
            <svg data-v-bd832875="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon mr-2 text-[18px]" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m8.85 16.825l3.15-1.9l3.15 1.925l-.825-3.6l2.775-2.4l-3.65-.325l-1.45-3.4l-1.45 3.375l-3.65.325l2.775 2.425zm3.15.45l-4.15 2.5q-.275.175-.575.15t-.525-.2t-.35-.437t-.05-.588l1.1-4.725L3.775 10.8q-.25-.225-.312-.513t.037-.562t.3-.45t.55-.225l4.85-.425l1.875-4.45q.125-.3.388-.45t.537-.15t.537.15t.388.45l1.875 4.45l4.85.425q.35.05.55.225t.3.45t.038.563t-.313.512l-3.675 3.175l1.1 4.725q.075.325-.05.588t-.35.437t-.525.2t-.575-.15zm0-5.025"></path></svg>
            <h4 className='m-0' style={{ fontSize: 16, fontWeight: 500 }}>Hạng mục đánh giá</h4>
          </div>


          <div className='bg-white px-3 py-2 mt-3 gap-3' style={{ borderLeftColor: "rgb(55, 65, 81)", borderLeftWidth: 2, borderLeftStyle: "solid" }}>
            <div>
            <svg data-v-bd832875="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-orange-600" width="1em" height="1em" viewBox="0 0 24 24"><path fill="rgb(234 88 12)" d="m12 17.275l-4.15 2.5q-.275.175-.575.15t-.525-.2t-.35-.437t-.05-.588l1.1-4.725L3.775 10.8q-.25-.225-.312-.513t.037-.562t.3-.45t.55-.225l4.85-.425l1.875-4.45q.125-.3.388-.45t.537-.15t.537.15t.388.45l1.875 4.45l4.85.425q.35.05.55.225t.3.45t.038.563t-.313.512l-3.675 3.175l1.1 4.725q.075.325-.05.588t-.35.437t-.525.2t-.575-.15z"></path></svg>
            <svg data-v-bd832875="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-orange-600" width="1em" height="1em" viewBox="0 0 24 24"><path fill="rgb(234 88 12)" d="m12 17.275l-4.15 2.5q-.275.175-.575.15t-.525-.2t-.35-.437t-.05-.588l1.1-4.725L3.775 10.8q-.25-.225-.312-.513t.037-.562t.3-.45t.55-.225l4.85-.425l1.875-4.45q.125-.3.388-.45t.537-.15t.537.15t.388.45l1.875 4.45l4.85.425q.35.05.55.225t.3.45t.038.563t-.313.512l-3.675 3.175l1.1 4.725q.075.325-.05.588t-.35.437t-.525.2t-.575-.15z"></path></svg>
            <svg data-v-bd832875="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-orange-600" width="1em" height="1em" viewBox="0 0 24 24"><path fill="rgb(234 88 12)" d="m12 17.275l-4.15 2.5q-.275.175-.575.15t-.525-.2t-.35-.437t-.05-.588l1.1-4.725L3.775 10.8q-.25-.225-.312-.513t.037-.562t.3-.45t.55-.225l4.85-.425l1.875-4.45q.125-.3.388-.45t.537-.15t.537.15t.388.45l1.875 4.45l4.85.425q.35.05.55.225t.3.45t.038.563t-.313.512l-3.675 3.175l1.1 4.725q.075.325-.05.588t-.35.437t-.525.2t-.575-.15z"></path></svg>
            <svg data-v-bd832875="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-orange-600" width="1em" height="1em" viewBox="0 0 24 24"><path fill="rgb(234 88 12)" d="m12 17.275l-4.15 2.5q-.275.175-.575.15t-.525-.2t-.35-.437t-.05-.588l1.1-4.725L3.775 10.8q-.25-.225-.312-.513t.037-.562t.3-.45t.55-.225l4.85-.425l1.875-4.45q.125-.3.388-.45t.537-.15t.537.15t.388.45l1.875 4.45l4.85.425q.35.05.55.225t.3.45t.038.563t-.313.512l-3.675 3.175l1.1 4.725q.075.325-.05.588t-.35.437t-.525.2t-.575-.15z"></path></svg>
            <svg data-v-bd832875="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-orange-600" width="1em" height="1em" viewBox="0 0 24 24"><path fill="rgb(234 88 12)" d="m12 17.275l-4.15 2.5q-.275.175-.575.15t-.525-.2t-.35-.437t-.05-.588l1.1-4.725L3.775 10.8q-.25-.225-.312-.513t.037-.562t.3-.45t.55-.225l4.85-.425l1.875-4.45q.125-.3.388-.45t.537-.15t.537.15t.388.45l1.875 4.45l4.85.425q.35.05.55.225t.3.45t.038.563t-.313.512l-3.675 3.175l1.1 4.725q.075.325-.05.588t-.35.437t-.525.2t-.575-.15z"></path></svg>
            </div>

            <p className='m-0' style={{ fontSize: 14, fontWeight: 400 }}> Hơn 530.000 lượt đánh giá </p>

          </div>

          <div className='text-center mt-5 pb-5'>
            <img style={{ width: 140 }} src={signed} />
          </div>



        </div>



      </div>
    </Layout>
  )
}

export default History
