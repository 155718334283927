import Layout from "./Layout";
import { useEffect, useState } from "react";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import axios from '../../../shared/config/axios-interceptor'
import { IHistory } from "@/shared/model/history.model";
import { RootState } from "@/reducers";
import { useSelector } from "react-redux";

const Rewards = () => {

    const { user } = useSelector((state: RootState) => state.authentication);

    const { navigate } = useRouter();

    const [opend, setOppend] = useState<IHistory[] | []>([])

    const getHoSoDaMo = async () => {
        try {
            const response = await axios.get(
                '/history?status=closed&sortOrder=DESC&sortBy=historyId&limit=100&userId=' + user?.id,
            )
            const win = response.data.data.filter((item: IHistory) => item.isWin == 1)
            setOppend(win)
        } catch (error) {
            setOppend([])
            console.error('Error:', error)
        }
    }


    useEffect(() => {
        getHoSoDaMo();
    }, [])


    return <Layout >
        <div className="box-history-order">


            <div className="box-header">
                <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />
                <h4 className="text-white text-center mb-0 mt-1" style={{ fontSize: 15 }}>Lịch sử tham gia</h4>
            </div>

            <div className="px-3">
                <div className="rounded-3 mt-3 py-1 px-3 text-white" style={{ background: "#eb426d", fontSize: 13 }}>
                    Tổng số: 0
                </div>
            </div>

            <div className="px-3 mt-3">
                <div className="d-flex justify-content-between py-1 px-3 rounded-3" style={{ background: "#d1d5db", fontSize: 12, color: "rgb(55, 65, 81)" }}>
                    <div style={{ width: "10%" }}>ID</div>
                    <div style={{ width: "17%" }}>Số phiếu</div>
                    <div style={{ width: "39%" }}>Nội dung</div>
                    <div style={{ width: "32%" }} className="text-end">Ngày thực hiện</div>
                </div>
            </div>

            <div className="box-history mt-3 pt-0">
                {
                    opend.map((item, index) => {
                        return <div key={index} className="d-flex justify-content-between py-1 px-3 mb-2 " style={{ fontSize: 12, color: "rgb(55, 65, 81)" }}>
                            <div style={{ width: "10%" }}>{index + 1}</div>
                            <div style={{ width: "17%" }}>{item?.historyId}</div>
                            <div style={{ width: "39%" }}>+{new Intl.NumberFormat('vi-VN').format(item?.money || 0)}</div>
                            <div style={{ width: "32%" }} className="text-end">{ new Date(item?.createdDate || "").toLocaleDateString() }</div>
                        </div>
                    })
                }

            </div>



        </div>
    </Layout>
}

export default Rewards;
